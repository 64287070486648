import './index.scss'

import React from 'react'
import API from '../../../../API'
import Lang from '../../../../Lang'
import { pick } from '../../../../Util'
import DeviceChoice from './DeviceChoice'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import ConfirmPopover from '../../../../Components/ConfirmPopover'

import ProfileV1Editor from './ProfileV1Editor'
import ProfileV2Editor from './ProfileV2Editor'
import { AppToaster } from '../../../../Components/App'

export default class ProfileDetails extends React.Component {
	constructor(props) {
		super(props)

		this.state = { profile: { version: 1, ...props.profile }, savingProfile: false }

		this.deviceChoiceRef = React.createRef()
		this.profileEditorRef = React.createRef()
	}

	setProfileValue(key, value) {
		const p = { ...this.state.profile }
		p[key] = value
		this.setState({ profile: p })
	}

	async save() {
		const newProfileData = {
			...this.profileEditorRef.current.getData(),
			...this.deviceChoiceRef.current.state,
			...pick(this.state.profile, ['title', 'version', 'id'])
		}
		const p = newProfileData

		this.setState({ savingProfile: true })

		if (p.id == 0) {
			const profileData = { ...p }
			delete profileData.id
			await API.call('LightingProfile.Create', profileData)
		} else {
			await API.call('LightingProfile.Update', p)
		}

		// ;(await AppToaster).show({ message: 'Profile saved!', intent: 'success' })

		this.setState({ savingProfile: false })

		this.props.selectProfile(null)
		this.props.reload(false)
	}

	async deleteProfile() {
		const p = this.state.profile
		if (p.id != 0) {
			await API.call('LightingProfile.Delete', { id: p.id })
			this.props.selectProfile(null)
			this.props.reload(false)
		}
	}

	render() {
		const p = this.state.profile
		return (
			<div className="ProfileDetails">
				<div className="closeButton">
					<Button icon="cross" minimal onClick={() => this.props.selectProfile(null)} />
				</div>

				<div className="content">
					<FormGroup label={Lang.get('Profile Title')}>
						<InputGroup value={p.title} onChange={(e) => this.setProfileValue('title', e.target.value)} />
					</FormGroup>

					{p.version == 1 && <ProfileV1Editor ref={this.profileEditorRef} profile={p} />}
					{p.version == 2 && <ProfileV2Editor ref={this.profileEditorRef} profile={p} />}

					<DeviceChoice ref={this.deviceChoiceRef} deviceIds={p.deviceIds} />
				</div>

				<div className="options">
					{this.state.profile.id != 0 && (
						<ConfirmPopover
							message={Lang.get('Are you sure?')}
							confirmTitle={Lang.get('Confirm')}
							onConfirm={() => this.deleteProfile()}>
							<Button text={Lang.get('Delete Profile')} icon="trash" intent="danger" />
						</ConfirmPopover>
					)}
					<div />
					<Button
						text={this.state.profile.id == 0 ? Lang.get('Create Profile') : Lang.get('Save')}
						icon={this.state.savingProfile ? <Spinner className="spinner-icon white" /> : 'floppy-disk'}
						intent="primary"
						onClick={() => this.save()}
						disabled={this.state.savingProfile}
					/>
				</div>
			</div>
		)
	}
}
