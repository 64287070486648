import { InputGroup } from '@blueprintjs/core'
import './ProfilesDevicesSidebar.scss'
import { useEffect, useState } from 'react'
import { AppToaster } from '../../../Components/App'
import QRDialog from './QRDialog'

import DeviceList from '../../Devices/Components/DeviceList'

export default function ProfilesDevicesSidebar({ open, onOpenChange, profiles, devices, onDeviceSelect }) {
	const [qrOpen, setQrOpen] = useState(false)
	const [searchingFor, setSearchingFor] = useState('')

	async function onQRScan(data) {
		// check if valid idealights scan
		const [prefix, code] = data.split('_')
		console.log(prefix, code)
		if (prefix !== 'idealights') {
			;(await AppToaster).show({ message: 'Invalid QR', intent: 'warning' })
			return false
		}

		setSearchingFor(code.trim())
	}

	return (
		<>
			<main className={`profiles-devices-sidebar ${open && 'open'}`}>
				<header>
					<InputGroup
						id="searching-for"
						placeholder="Search..."
						value={searchingFor}
						onChange={(e) => setSearchingFor(e.target.value)}
						leftIcon="search"
					/>

					<button className="bp5-button" onClick={() => setQrOpen(true)}>
						<i className="fa-solid fa-qrcode"></i>
					</button>
				</header>

				{devices && profiles && (
					<DeviceList
						devices={devices}
						lightProfiles={profiles}
						searchingFor={searchingFor}
						selectedProfile={null}
						selectProfile={() => {}}
						selectDevice={onDeviceSelect}
					/>
				)}
			</main>

			<QRDialog open={qrOpen} setOpen={setQrOpen} onScan={onQRScan} />
		</>
	)
}
