import './Map.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import Lang from '../Lang'
import mapboxgl from 'mapbox-gl'
import { Popover2 } from '@blueprintjs/popover2'
import { Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { mapOverlays } from '../Assets/overlays'

const Map = ({ onViewStateChange, onReady, onClick, onTrackUserLocationStart }) => {
	const [style, setStyle] = useState('map')
	const [iconColors, setIconColors] = useState('lighting-profile')

	const [waitingForCoordinates, setWaitingForCoordinates] = useState(false)
	const userCoordsRef = useRef(null)

	const mapElRef = useRef()
	const geoControlRef = useRef()
	const ref = useRef()

	useEffect(() => {
		ref.current = new mapboxgl.Map({
			container: mapElRef.current,
			style: 'mapbox://styles/mapbox/navigation-day-v1',
			center: [24.1056, 56.9677],
			zoom: 6
			// projection: 'globe'
		})

		geoControlRef.current = new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			fitBoundsOptions: {
				zoom: 10
			},
			trackUserLocation: true,
			showUserHeading: true
		})

		ref.current.addControl(geoControlRef.current)

		// if (onTrackUserLocationStart) {
		// 	geoControlRef.current.on(
		// 		'trackuserlocationstart',
		// 		() => userCoordsRef.current && onTrackUserLocationStart(userCoordsRef.current)
		// 	)
		// }

		geoControlRef.current.on('geolocate', (pos) => {
			const coords = [pos.coords.latitude, pos.coords.longitude]

			userCoordsRef.current = coords
			setWaitingForCoordinates(false)
		})

		ref.current.on('style.load', () => {
			for (let overlay of mapOverlays) {
				if (overlay.accountId == window.App.account?.id) {
					ref.current.addSource(overlay.id + '-layer', {
						type: 'image',
						url: overlay.image,
						coordinates: overlay.coordinates
					})
					ref.current.addLayer({
						id: 'overlays-layer',
						type: 'raster',
						source: overlay.id + '-layer'
					})
					ref.current.setPaintProperty('overlays-layer', 'raster-opacity', 0.8)
				}
			}
		})

		ref.current.on('contextmenu', (e) => {
			console.log(e.lngLat.lat, e.lngLat.lng)
		})

		onReady(ref)

		return () => ref.current.remove()
	}, [])

	function setViewState(newState) {
		if (newState.style) {
			setStyle(newState.style)

			if (newState.style == 'satellite') {
				ref.current.setStyle('mapbox://styles/mapbox/satellite-streets-v12')
			} else if (newState.style == 'map') {
				ref.current.setStyle('mapbox://styles/mapbox/navigation-day-v1')
			}
		}

		if (newState.iconColors) {
			setIconColors(newState.iconColors)
		}

		if (onViewStateChange) {
			onViewStateChange(newState)
		}
	}

	useEffect(() => {
		if (!waitingForCoordinates) {
			if (onTrackUserLocationStart && userCoordsRef.current) {
				onTrackUserLocationStart(userCoordsRef.current)
			}
		}
	}, [waitingForCoordinates])

	function triggerLocate() {
		geoControlRef.current?.trigger()

		if (!userCoordsRef.current) {
			setWaitingForCoordinates(true)
		}

		if (onTrackUserLocationStart && userCoordsRef.current) {
			onTrackUserLocationStart(userCoordsRef.current)
		}
	}

	const viewMenu = (
		<Menu>
			<MenuDivider title={Lang.get('View Type')} />
			<MenuItem icon={style == 'map' ? 'tick' : 'blank'} text={Lang.get('Map')} onClick={() => setViewState({ style: 'map' })} />
			<MenuItem
				icon={style == 'satellite' ? 'tick' : 'blank'}
				text={Lang.get('Satellite')}
				onClick={() => setViewState({ style: 'satellite' })}
			/>
			<MenuDivider title={Lang.get('Icon Color')} />
			<MenuItem
				icon={iconColors == 'lighting-profile' ? 'tick' : 'blank'}
				text={Lang.get('Lighting Profile')}
				onClick={() => setViewState({ iconColors: 'lighting-profile' })}
			/>
			<MenuItem
				icon={iconColors == 'device-status' ? 'tick' : 'blank'}
				text={Lang.get('Device Status')}
				onClick={() => setViewState({ iconColors: 'device-status' })}
			/>
			<MenuItem
				icon={iconColors == 'report-health' ? 'tick' : 'blank'}
				text={Lang.get('Report Health')}
				onClick={() => setViewState({ iconColors: 'report-health' })}
			/>
			<MenuItem
				icon={iconColors == 'operative' ? 'tick' : 'blank'}
				text={Lang.get('Operative')}
				onClick={() => setViewState({ iconColors: 'operative' })}
			/>
		</Menu>
	)
	return (
		<div className="Map">
			<div className="tools">
				<Popover2 content={viewMenu} placement="bottom-end">
					<Button icon="eye-open" />
				</Popover2>

				<Button icon="locate" loading={waitingForCoordinates} onClick={triggerLocate} />
			</div>
			<div className="container" ref={mapElRef} onClick={onClick} />
		</div>
	)
}

export default Map
