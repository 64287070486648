import './Placeholder.scss'

import React from 'react'
import { Spinner } from '@blueprintjs/core'

export default function Placeholder({ overlay, overlayVisible, className, icon, loading, label }) {
	return (
		<div className={`Placeholder ${overlay && `overlay ${overlayVisible && 'overlay-visible'}`} ${className}`}>
			{icon && (
				<div className="icon">
					<i className={icon} />
				</div>
			)}
			{loading && <Spinner />}
			{label && <div className="label">{label}</div>}
		</div>
	)
}
