import './index.scss'

import React from 'react'
import Page from '../../Components/Page'
import Placeholder from '../../Components/Placeholder'

export default function PlaceholderPage({ loading, title, icon, message }) {
	return (
		<Page title={title} icon={icon} className="PlaceholderPage" mobileViewport>
			<Placeholder icon={icon} label={message} loading={loading} />
		</Page>
	)
}
