import { useMemo, useState } from 'react'
import API, { RFCommand, RFPacket } from '../../../API'
import { DeviceDetailsPingButton } from '../../Devices/Components/DeviceDetails'
import { parse } from 'path-browserify'
import { Icon, InputGroup } from '@blueprintjs/core'

export default function Actions({ device }) {
	const [gateway, setGateway] = useState(-1)
	const gatewayCon = useMemo(() => {
		const g = API.connections.find((con) => con.scanReport?.deviceId == parseInt(gateway))

		const num = g?.id ? parseInt(g.id) : parseInt(gateway)
		return isNaN(num) ? -1 : num
	}, [gateway])

	async function deviceAction(action) {
		if (!device) return

		const p = new RFPacket()

		switch (action) {
			case 'on':
				p.command = RFCommand.RELAY_TEST
				p.data = Buffer.from('01', 'hex')
				break
			case 'off':
				p.command = RFCommand.RELAY_TEST
				p.data = Buffer.from('00', 'hex')
				break
			case 'blink':
				p.command = RFCommand.IDENTIFY
				break
			default:
				console.error('Unknown action: ' + action)
				return
		}

		API.sendPacket(p, device, gatewayCon)
	}

	// console.log(API.connections)

	return (
		<div className="device-actions">
			<div>
				<InputGroup
					leftIcon="cell-tower"
					placeholder="Gateway device ID"
					value={gateway}
					onChange={(e) => setGateway(e.target.value)}
				/>
				<span className="gateway-con-id">{gatewayCon != gateway && gatewayCon != -1 && gatewayCon}</span>
			</div>

			<div>
				<button className="bp5-button" onClick={() => deviceAction('on')}>
					Relay ON
				</button>
				<button className="bp5-button" onClick={() => deviceAction('off')}>
					Relay OFF
				</button>
			</div>

			<div>
				<button className="bp5-button" onClick={() => deviceAction('blink')}>
					<i className="fa-solid fa-lightbulb"></i> Blink DALI
				</button>
				<DeviceDetailsPingButton device={device} preferGateway={gatewayCon} />
			</div>
		</div>
	)
}
