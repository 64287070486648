{
	"Are you sure?": "Vai esat pārliecināts?",
	"Confirm": "Apstiprināt",
	"Cancel": "Atcelt",
	"Log Out": "Izrakstīties",
	"Devices": "Ierīces",
	"Reports": "Pārskati",
	"Settings": "Iestatījumi",
	"Unknown": "Nezināms",
	"Consumption": "Patēriņš",
	"Traffic": "Satiksme",
	"Events": "Notikumi",
	"C4 Controller": "C4 kontrolieris",
	"General": "Vispārīgi",
	"History": "Vēsture",
	"Neighbour Lights": "Kaimiņu gaismas",
	"Device ID": "Ierīces ID",
	"Multiplier (0-1)": "Reizinātājs (0-1)",
	"Saving...": "Saglabā...",
	"Save Neighbours": "Saglabāt kaimiņus",
	"Last Heard": "Pēdējais dzirdētais",
	"Title": "Nosaukums",
	"Info": "Informācija",
	"Serial Nr": "Sērijas Nr",
	"Firmware": "Programmaparatūra",
	"Save": "Saglabāt",
	"Actions": "Darbības",
	"Show on Map": "Rādīt kartē",
	"Blink": "Mirgot",
	"ON": "IESLĒGTS",
	"OFF": "IZSLĒGTS",
	"Ping": "Ping",
	"Other Devices": "Citas ierīces",
	"GSM Gateway": "GSM vārteja",
	"Installing configuration...": "Konfigurācijas instalēšana...",
	"No signal": "Nav signāla",
	"Last signal %s months ago": "Pēdējais signāls pirms %s mēnešiem",
	"Last signal %s weeks ago": "Pēdējais signāls pirms %s nedēļām",
	"Last signal %s days ago": "Pēdējais signāls pirms %s dienām",
	"Add Devices": "Pievienot ierīces",
	"Weekdays": "Darbdienas",
	"Nightlight": "Naktslampa",
	"Daylight": "Dienas gaisma",
	"Date": "Datums",
	"This condition is incompatible with existing conditions": "Šis nosacījums nav saderīgs ar esošajiem nosacījumiem",
	"Active": "Aktīvs",
	"Note": "Piezīme",
	"Duplicate": "Dublikāts",
	"Conditions": "Nosacījumi",
	"No conditions": "Nav nosacījumu",
	"Brightness levels": "Gaišuma līmeņi",
	"No Motion": "Nav kustības",
	"Motion": "Kustība",
	"Brightness change speed (DALI)": "Gaišuma izmaiņu ātrums (DALI)",
	"Motion time": "Kustības ilgums",
	"seconds": "sekundes",
	"The first schedule for which all conditions are true will be activated": "Tiks aktivizēts pirmais grafiks, kurā visi nosacījumi ir patiesi",
	"Add Schedule": "Pievienot grafiku",
	"September 1st": "1. septembris",
	"Workdays": "Darba dienas",
	"Other Days": "Citas dienas",
	"Profile Title": "Profila nosaukums",
	"Delete Profile": "Dzēst profilu",
	"Create Profile": "Izveidot profilu",
	"No Profile": "Nav profila",
	"New Profile": "Jauns profils",
	"Create New Profile": "Izveidot jaunu profilu",
	"January": "Janvāris",
	"February": "Februāris",
	"March": "Marts",
	"April": "Aprīlis",
	"May": "Maijs",
	"June": "Jūnijs",
	"July": "Jūlijs",
	"August": "Augusts",
	"September": "Septembris",
	"October": "Oktobris",
	"November": "Novembris",
	"December": "Decembris",
	"Day": "Diena",
	"Hour": "Stunda",
	"Month": "Mēnesis",
	"Year": "Gads",
	"All Devices": "Visas Ierīces",
	"Lighting Profiles": "Gaismas Profili",
	"Close": "Aizvērt",
	"Filter": "Filtrs",
	"Account Name": "Konta Nosaukums",
	"Time Zone": "Laika Zona",
	"E-mail": "E-pasts",
	"Password": "Parole",
	"Continue": "Turpināt",
	"Invalid login or password": "Nepareizs lietotājvārds vai parole",
	"Device": "Iekārta",
	"Remove Neighbour": "Noņemt Kaimiņu",
	"Add Neighbour": "Pievienot Kaimiņu",
	"View Type": "Skats",
	"Map": "Karte",
	"Satellite": "Satelīts",
	"Icon Color": "Ikonas Krāsa",
	"Lighting Profile": "Gaismas Profils",
	"Device Status": "Iekārtas Statuss",
	"Faults": "Brīdinājumi",
	"Ongoing Faults": "Patreizējie Brīdinājumi",
	"Unreacheable": "Nesasniedzams",
	"Resolved Faults": "Atrisinātie Brīdinājumi",
	"There are no faults at the moment.": "Šobrīd bojājumu nav.",
	"minutes": "minūtes",
	"hours": "stundas",
	"days": "dienas",
	"Duration": "Ilgums",
	"From": "No",
	"To": "Līdz",
	"Search...": "Meklēt...",
	"Period": "Periods",
	"Custom": "Pielāgots",
	"Last 3 months": "Pēdējie 3 mēneši",
	"Last month": "Pagājušomēnes",
	"This month": "Šomēnes",
	"Last week": "Pagājušonedēļ",
	"This week": "Šonedēļ",
	"Yesterday": "Vakar",
	"Today": "Šodien",
	"Parameters": "Parametri",
	"Lamp Short Address": "Īsā adrese",
	"Nominal Power": "Nominālā jauda",
	"Min Dim Level From Driver": "Draivera min. Dim līmenis",
	"Current Dim Level": "Pašreizējais Dim līmenis",
	"Total Active Energy": "Kopējā aktīvā enerģija",
	"Driver On Time": "Draivera darbības laiks",
	"Mains Voltage": "Tīkla spriegums",
	"Mains Current": "Tīkla strāva",
	"Power Factor": "Jaudas koeficients",
	"Frequency": "Frekvence",
	"Driver Failure Flags": "Draivera kļūds",
	"Driver Temperature": "Draivera temperatūra",
	"LED On Time": "LED darbības laiks",
	"LED Failure Flags": "LED kļūdas",
	"LED Temperature": "LED temperatūra",
	"LED Voltage": "LED spriegums",
	"LED Current": "LED strāva",
	"Invalid ID": "Nederīgs ID",
	"MB Consumption": "",
	"MB Voltage": "",
	"MB Current": "",
	"MB Active power": "",
	"MB Reactive power": "",
	"MB Apparent power": "",
	"MB Total active energy": "",
	"Relative view": "Relatīvais skats",
	"All": "Visi",
	"Step": "Solis",
	"No data": "Nav datu",
	"CSV export": "CSV eksports",
	"Selected device count: %d": "Atlasīto ierīču skaits: %d",
	"Download": "Lejupielādēt",
	"Loading devices": "Ielādē ierīces",
	"Loading reports": "Ielādē pārskatus",
	"Loading faults": "Ielādē brīdinājumus",
	"Active Power": "Aktīvā Jauda",
	"Query": "Atlasīt",
	"Unit": "Vienība",
	"Time": "Laiks",
	"Value": "Vērtība",
	"Export type": "Eksporta tips",

	"Unexpectedly glowing": "Negaidīti spīd",
	"Unexpectedly dark": "Negaidīti tumšs",
	"Consumption too high": "Patēriņš pārāk liels",
	"Invalid dim level relative to profile": "Nederīgs dim līmenis attiecībā pret profilu",

	"Active Events": "Aktīvie Notikumi",
	"Ended Events": "Beigušies Notikumi"
}
