import './ReplaceDialog.scss'

import { useState } from 'react'
import { Dialog, DialogBody } from '@blueprintjs/core'

export default function ReplaceDialog({ open, setOpen, onResult }) {
	const [type, setType] = useState('replace')

	async function handleReplace() {}

	return (
		<Dialog isOpen={open} onClose={() => setOpen(false)} className="replace-dialog">
			<DialogBody className="body">
				<p className="header">Replace / swap</p>

				<div className="bp5-html-select" style={{ width: '100%' }}>
					<select style={{ fontSize: 'large' }} onChange={(e) => setType(e.target.value)} value={type}>
						<option value={'replace'}>Replace</option>
						<option value={'swap'}>Swap</option>
					</select>
					<span className="bp5-icon bp5-icon-double-caret-vertical" />
				</div>

				<button className="bp5-button replace-btn" onClick={handleReplace}>
					{type}
				</button>
			</DialogBody>
		</Dialog>
	)
}
