import virsiTvaikaLayer from './virsi-tvaika.png'
import virsiSateklesLayer from './virsi-satekles.png'
import virsiOlaineLayer from './virsi-olaine.png'
import virsiViskaluLayer from './virsi-viskalu.png'
import virsiSiguldaLayer from './virsi-sigulda.png'
import virsiSalaspilsLayer from './virsi-salaspils.png'

export const mapOverlays = [
	{
		id: 'virsi-tvaika',
		image: virsiTvaikaLayer,
		accountId: 111,
		coordinates: [
			[24.1152479228463, 56.99838186651575],
			[24.11717991915333, 56.99838186651575],
			[24.11717991915333, 56.99738496280126],
			[24.11524722222222, 56.99738611111111]
		]
	},
	{
		id: 'virsi-satekles',
		image: virsiSateklesLayer,
		accountId: 108,
		coordinates: [
			[24.12795931083174, 56.94712618505658],
			[24.12795285641126, 56.94767987844558],
			[24.12967176876184, 56.94767979296833],
			[24.12966284796274, 56.94716272048972]
		]
	},
	{
		id: 'virsi-olaine',
		image: virsiOlaineLayer,
		accountId: 113,
		coordinates: [
			[23.968173015512598, 56.795743636036995],
			[23.971072778660297, 56.795752058867365],
			[23.971083612790235, 56.79462893222509],
			[23.968183849964586, 56.79462050939567]
		]
	},
	{
		id: 'virsi-viskalu',
		image: virsiViskaluLayer,
		accountId: 114,
		coordinates: [
			[24.181836947533316, 56.98413084151486],
			[24.18360051268902, 56.98414496892944],
			[24.183637166768484, 56.982785241232264],
			[24.181873602937525, 56.9827711138284]
		]
	},
	{
		id: 'virsi-sigulda',
		image: virsiSiguldaLayer,
		accountId: 115,
		coordinates: [
			[24.876758150837663, 57.150869426827406],
			[24.879487990895974, 57.15083631966974],
			[24.879444818684757, 57.149789814310935],
			[24.876714977366543, 57.14982292148342]
		]
	},
	{
		id: 'virsi-salaspils',
		image: virsiSalaspilsLayer,
		accountId: 116,
		coordinates: [
			[24.35955736723016, 56.848114626377814],
			[24.3620531647819, 56.84809966012389],
			[24.362027416923965, 56.84681762908176],
			[24.35953161847991, 56.84683259534099]
		]
	}
]
