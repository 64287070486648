import './index.scss'

import React, { useEffect, useRef } from 'react'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import API from '../../API'
import Lang from '../../Lang'
import Map from '../../Components/Map'
import Page from '../../Components/Page'
import { DateTime } from 'luxon'
import DeviceDetails from '../Devices/Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'
import { useState } from 'react'
import { AdminOnly } from '../../Util'

export default function FaultsPage() {
	const [selectedDevice, setSelectedDevice] = useState(null)
	const [faults, setFaults] = useState(null)
	const [events, setEvents] = useState(null)
	const [devices, setDevices] = useState([])

	const mapRef = useRef(null)

	useEffect(() => {
		mapRef.current.resize()

		if (selectedDevice) {
			const curZoom = mapRef.current.getZoom()
			const targetZoom = 15

			const flyToOptions = {
				center: [selectedDevice.longitude, selectedDevice.latitude],
				...(curZoom < targetZoom && { zoom: targetZoom }),
				essential: true
			}
			mapRef.current.flyTo(flyToOptions)
		}
	}, [selectedDevice])

	function onDeviceSelect(device) {
		selectDevice(device)
	}

	function selectDevice(device) {
		setSelectedDevice((curDevice) => {
			if (device?.id === curDevice?.id) return curDevice

			if (curDevice) {
				curDevice.marker?.getElement().classList.remove('selected')
			}

			if (device) {
				setDevices((devices) => {
					device = devices.find((d) => d.id === device.id)

					if (device) {
						device.marker?.getElement().classList.add('selected')
					}

					return devices
				})
			}

			return device
		})
	}

	function zoomDevices(devices) {
		if (devices.length === 0) return

		const zoomBounds = new LngLatBounds()
		for (let d of devices) {
			if (!d.latitude || !d.longitude) continue

			zoomBounds.extend([d.longitude, d.latitude])
		}

		mapRef.current.fitBounds(zoomBounds, { padding: 90, duration: 250 })
	}

	function createMarkerElementForDevice(dev) {
		const markerElement = document.createElement('div')
		markerElement.className = 'Marker DeviceMarker'

		markerElement.style.backgroundColor = '#ff8c00'

		const iconElement = document.createElement('i')
		iconElement.className = 'fa-fw fa-duotone fa-triangle-exclamation'
		markerElement.appendChild(iconElement)

		const labelElement = document.createElement('div')
		labelElement.className = 'label'
		labelElement.innerText = dev.id
		markerElement.appendChild(labelElement)

		return markerElement
	}

	async function hydrate() {
		const res = await API.call('FaultsAndEvents.Get')

		const _faults = res.faults
		const _events = res.events

		console.log(_events)

		setFaults(_faults)
		setEvents(_events)

		if (!mapRef.current) return

		const devices = []
		for (let f of [..._faults, ..._events]) {
			const d = f.device

			if (!d.latitude || !d.longitude) continue
			if (f.ended) continue

			if (devices.find((dev) => dev.id === d.id)) continue

			devices.push(d)

			const markerElement = createMarkerElementForDevice(d)
			const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat([d.longitude, d.latitude]).addTo(mapRef.current)

			markerElement.addEventListener('click', (e) => onDeviceSelect(d))
			d.marker = marker
		}

		zoomDevices(devices)
		setDevices(devices)
	}

	return (
		<Page title={Lang.get('Faults')} icon="fa-duotone fa-triangle-exclamation" className="FaultsPage" noPadding>
			<Placeholder overlay overlayVisible={faults == null} loading label={Lang.get('Loading faults') + ' ...'} />

			{faults && events && (
				<>
					{faults && (
						<div className="SideContainer">
							<AdminOnly>
								{events.length !== 0 &&
									(() => {
										const currentEvents = events.filter((fault) => fault.ended === null)
										const previousEvents = events.filter((fault) => fault.ended !== null)
										return (
											<>
												{currentEvents.length !== 0 && (
													<EventsList title={Lang.get('Active Events')} items={currentEvents} onDeviceSelect={onDeviceSelect} />
												)}
												{previousEvents.length !== 0 && (
													<EventsList
														title={Lang.get('Ended Events')}
														items={previousEvents}
														onDeviceSelect={onDeviceSelect}
														defaultCollapsed
													/>
												)}
											</>
										)
									})()}
							</AdminOnly>

							{faults.length === 0
								? Lang.get('There are no faults at the moment.')
								: (() => {
										const currentFaults = faults.filter((fault) => fault.ended === null)
										const previousFaults = faults.filter((fault) => fault.ended !== null)
										return (
											<>
												<FaultsList title={Lang.get('Ongoing Faults')} items={currentFaults} onDeviceSelect={onDeviceSelect} />
												<FaultsList
													title={Lang.get('Resolved Faults')}
													items={previousFaults}
													onDeviceSelect={onDeviceSelect}
													defaultCollapsed
												/>
											</>
										)
								  })()}
						</div>
					)}
					{selectedDevice && (
						<DeviceDetails
							device={selectedDevice}
							view={{
								selectDevice,
								zoomDevices
							}}
						/>
					)}
				</>
			)}

			<div className="MapContainer">
				<Map
					onReady={(ref) => {
						mapRef.current = ref.current
						hydrate()
					}}
				/>
			</div>
		</Page>
	)
}

const EVENT_LABELS = {
	GHOST_ON: Lang.get('Unexpectedly glowing'),
	GHOST_OFF: Lang.get('Unexpectedly dark'),
	CONSUMPTION_TOO_HIGH: Lang.get('Consumption too high'),
	INVALID_DIM_LVL: Lang.get('Invalid dim level relative to profile')
}

function EventsList({ items, defaultCollapsed = true, title, onDeviceSelect }) {
	const [filterType, setFilterType] = useState('all')

	const [collapsed, setCollapsed] = useState(defaultCollapsed)

	// let itemsToShow = !collapsed ? (filterType === 'all' ? items : items.filter((e) => e.data.type == filterType)) : []
	let itemsToShow = !collapsed ? items : []
	return (
		<div className="FaultsList">
			<div className="header" onClick={() => setCollapsed(!collapsed)}>
				<i className={'fa-fw expander fa-duotone ' + (collapsed ? 'fa-chevron-right' : 'fa-chevron-down')} />
				<span>
					{title} ({items.length})
				</span>
			</div>
			{/* {!collapsed && (
				<div className="event-filter">
					<div className="bp5-html-select">
						<select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
							<option value={'all'}>All</option>
							{EVENT_TYPES.map((type, idx) => (
								<option key={`etf${idx}`} value={type}>
									{type}
								</option>
							))}
						</select>
						<span className="bp5-icon bp5-icon-double-caret-vertical" />
					</div>
				</div>
			)} */}
			<div className="content">
				{itemsToShow.map((event, i) => {
					const t = DateTime.fromISO(event.created)
					let duration = null
					if (event.ended) {
						duration = DateTime.fromISO(event.ended).diff(t)
					} else {
						duration = DateTime.now().diff(t)
					}

					// 14 minutes or 3 hours or 10 days
					if (duration.as('minutes') < 60) {
						duration = duration.toFormat('mm') + ' ' + Lang.get('minutes')
					} else if (duration.as('hours') < 24) {
						duration = duration.toFormat('hh') + ' ' + Lang.get('hours')
					} else {
						duration = duration.toFormat('dd') + ' ' + Lang.get('days')
					}
					if (duration[0] === '0') {
						duration = duration.slice(1)
					}

					return (
						<div className="Fault" key={`event-${i}`} onClick={() => onDeviceSelect(event.device, { from: 'list' })}>
							<div className="icon">
								<i className="fa-duotone fa-triangle-exclamation" />
							</div>
							<div className="details">
								<div className="deviceId">
									{event.device.id} - <span className="type">{EVENT_LABELS[event.type]}</span>
								</div>

								<div className="from">
									<strong>{Lang.get('From')}:</strong>
									{t.toFormat('dd.LL.yy HH:mm')}
								</div>
								{event.ended ? (
									<div className="to">
										<strong>{Lang.get('To')}:</strong>
										{DateTime.fromISO(event.ended).toFormat('dd.LL.yy HH:mm')}
									</div>
								) : (
									event.updated && (
										<div className="to">
											<strong>{Lang.get('Updated')}:</strong>
											{DateTime.fromISO(event.updated).toFormat('dd.LL.yy HH:mm')}
										</div>
									)
								)}
								<div className="duration">
									<strong>{Lang.get('Duration')}:</strong>
									{duration}
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

function FaultsList({ items, defaultCollapsed = false, title, onDeviceSelect }) {
	const [collapsed, setCollapsed] = useState(defaultCollapsed)

	let itemsToShow = !collapsed ? items : []
	return (
		<div className="FaultsList">
			<div className="header" onClick={() => setCollapsed(!collapsed)}>
				<i className={'fa-fw expander fa-duotone ' + (collapsed ? 'fa-chevron-right' : 'fa-chevron-down')} />
				<span>
					{title} ({items.length})
				</span>
			</div>
			<div className="content">
				{itemsToShow.map((fault, i) => {
					const t = DateTime.fromISO(fault.started)
					let duration = null
					if (fault.ended) {
						duration = DateTime.fromISO(fault.ended).diff(t)
					} else {
						duration = DateTime.now().diff(t)
					}

					// 14 minutes or 3 hours or 10 days
					if (duration.as('minutes') < 60) {
						duration = duration.toFormat('mm') + ' ' + Lang.get('minutes')
					} else if (duration.as('hours') < 24) {
						duration = duration.toFormat('hh') + ' ' + Lang.get('hours')
					} else {
						duration = duration.toFormat('dd') + ' ' + Lang.get('days')
					}
					if (duration[0] === '0') {
						duration = duration.slice(1)
					}

					return (
						<div className="Fault" key={`fault-${i}`} onClick={() => onDeviceSelect(fault.device, { from: 'list' })}>
							<div className="icon">
								<i className="fa-duotone fa-triangle-exclamation" />
							</div>
							<div className="details">
								<div className="deviceId">
									{fault.device.id} - <span className="type">{Lang.get('Unreacheable')}</span>
								</div>

								<div className="from">
									<strong>{Lang.get('From')}:</strong>
									{t.toFormat('dd.LL.yy HH:mm')}
								</div>
								{fault.ended && (
									<div className="to">
										<strong>{Lang.get('To')}:</strong>
										{DateTime.fromISO(fault.ended).toFormat('dd.LL.yy HH:mm')}
									</div>
								)}
								<div className="duration">
									<strong>{Lang.get('Duration')}:</strong>
									{duration}
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
